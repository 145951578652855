._1-wtg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
}

._2sohj {
  flex: auto auto 0 0;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._2vJBP {
  float: right;
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  text-align: left;
}

._3afez > ul {
  list-style-type: none;
}

._1ryT8 > ul > li {
  position: relative;
}

._VrCvP {
  position: relative;
  cursor: pointer;
  padding: 10px 40px;
}

._3FIQ_ {
  position: relative;
  padding: 24px 36px;
  box-shadow: 0 2px 10px 0 rgba(33, 33, 33, 0.1);
  background-color: #fafafa;
  overflow: hidden;
}

._1-xF_ {
  font-weight: bold;
}

._ve3dP {
  display: none;
}

._1lLZK {
  display: block;
}

._2CMVI {
  display: block;
  position: absolute;
  top: 0;
  left: -23px;
  width: 28px;
  border-radius: 5px;
  height: 100%;
}

._1Ekp3 {
  display: none;
  position: absolute;
  top: 0;
  left: -23px;
  width: 28px;
  border-radius: 5px;
  height: 100%;
}

._18n05 {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: 32px;
  left: 0;
}

._3FIQ_ ._18n05 {
  display: none;
}
